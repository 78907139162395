import { GetStaticProps, NextPage } from 'next'
import {
  Button,
  Footer,
  Headline,
  LinkTo,
  PageContainer,
  Pgraph,
} from '../../components'
import { useRouter } from 'next/router'
import { URLS, SOCIAL_MEDIA } from '../../config'
import { createSocialRequest } from '../../config/authorization'
import React, { useEffect, useState } from 'react'
import { MainPageNavigationProps } from '../../interfaces/navigation.interfaces'
import { WebConfService } from '../../services'
import Link from 'next/link'

export const getStaticProps: GetStaticProps<
  MainPageNavigationProps
> = async () => {
  const navigationJson = await WebConfService.getNavigationStructure()
  if (!navigationJson) throw new Error('Error fetching Navigation Structure')

  return {
    props: {
      navigation: navigationJson,
    },
    revalidate: 300,
  }
}

const Signup: NextPage<MainPageNavigationProps> = ({ navigation }) => {
  const router = useRouter()

  const [error, setError] = useState<string>('')

  const handleEmailLoginClick = () => {
    router.push(URLS.LOGIN.EMAIL)
  }

  const handleGoogleLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.GOOGLE))
    // signIn(SOCIAL_MEDIA.GOOGLE)
  }

  const handleFacebookLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.FACEBOOK))
    //signIn(SOCIAL_MEDIA.FACEBOOK)
  }

  const handleAppleLoginClick = () => {
    router.push(createSocialRequest(SOCIAL_MEDIA.APPLE))
    // signIn(SOCIAL_MEDIA.APPLE)
  }

  useEffect(() => {
    if (router.query['error']) {
      setError(decodeURIComponent(router.query['error'].toString()))
    }
  }, [router.query])

  return (
    <PageContainer
      navigation={navigation}
      seoAttributes={{
        title: 'Log in',
      }}
    >
      <div className="relative flex justify-center h-full">
        <div className="absolute top-0 left-0 h-full w-full -z-50 hidden md:block">
          <img src="/images/family-playing-background.jpg" alt="Background" />
        </div>
        <div className="mx-4 py-4 md:bg-snow-white md:p-5 md:m-5 md:rounded md:w-1/2 lg:w-1/3 md:h-fit">
          <Headline variant="recoleta-l" className="mb-5">
            Log in
          </Headline>
          {error && (
            <Pgraph
              variant="l-14"
              className="text-center text-caution-2 my-[20px]"
            >
              {error}{' '}
            </Pgraph>
          )}
          <Button
            label="Log in with email"
            primary
            className="w-full mb-2"
            onClick={handleEmailLoginClick}
            data-cy="SIGNIN_EMAIL"
          />
          <div className="relative">
            <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
              <img src="/images/social_google.svg" alt="Background" />
            </div>
            <Button
              label="Log in with Google"
              className="w-full mb-2"
              onClick={handleGoogleLoginClick}
              data-cy="SIGNIN_GOOGLE"
            />
          </div>
          <div className="relative">
            <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
              <img src="/images/social_facebook.svg" alt="Background" />
            </div>
            <Button
              label="Log in with Facebook"
              className="w-full mb-2"
              onClick={handleFacebookLoginClick}
              data-cy="SIGNIN_FACEBOOK"
            />
          </div>
          <div className="relative">
            <div className="inline-block absolute z-[1] top-[11px] left-[7px] md:left-[23px]">
              <img src="/images/social_apple.svg" alt="Background" />
            </div>
            <Button
              label="Log in with Apple"
              className="w-full mb-2"
              onClick={handleAppleLoginClick}
              data-cy="SIGNIN_APPLE"
            />
          </div>
          <Pgraph variant="p-14" className="text-center mt-4">
            New to Loop?{' '}
            <Link href={URLS.SIGNUP.INDEX} data-cy="SIGNIN_GET_STARTED" className='font-circular-medium'>
              Get started
            </Link>
          </Pgraph>
        </div>
      </div>
    </PageContainer>
  )
}

export default Signup
